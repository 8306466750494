import { createRouter, createWebHistory } from 'vue-router';

import Main from '../views/main.vue';

const routes = [
	{
		path: '/',
		name: 'Main',
		component: Main
	},
	{
		path: '/admin',
		name: 'Admin',
		component: () => import(/* webpackChunkName: "admin" */ '../views/admin/index.vue')
	},
	{
		path: '/terms.html',
		name: 'Terms',
		component: () => import(/* webpackChunkName: "main" */ '../views/terms.vue')
	},
	{
		path: '/privacy.html',
		name: 'Privacy',
		component: () => import(/* webpackChunkName: "main" */ '../views/privacy.vue')
	},
	/* --------------------- protected ------------ */
	{
		path: '/aureis_ai_platform.html',
		name: 'AureisAiPlatform',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "profile" */ '../views/protected/reports/request/index.vue')
	},
	{
		path: '/payments/success.html',
		name: 'PaymentSuccess',
		// /!* webpackChunkName: "login" *!/
		component: () => import('../views/payments/success.vue')
	},
	{
		path: '/payments/failed.html',
		name: 'PaymentsFailed',
		// /!* webpackChunkName: "login" *!/
		component: () => import('../views/payments/failed.vue')
	},
	{
		path: '/login.html',
		name: 'Login',
		// /!* webpackChunkName: "login" *!/
		component: () => import('../views/login.vue')
	},
	{
		path: '/reset/:key',
		name: 'ResetPassword',
		// /!* webpackChunkName: "login" *!/
		component: () => import('../views/reset.vue')
	},
	{
		path: '/register.html',
		name: 'Register',
		// /!* webpackChunkName: "login" *!/
		component: () => import('../views/register.vue')
	},
	{
		path: '/activate/:key',
		name: 'Activate',
		component: () => import(/* webpackChunkName: "login" */ '../views/activate.vue')
	},
	{
		path: '/profile/settings.html',
		name: 'Settings',
		component: () => import(/* webpackChunkName: "profile" */ '../views/protected/profile/settings.vue')
	},
	{
		path: '/profile/reports.html',
		name: 'Report',
		component: () => import(/* webpackChunkName: "profile" */ '../views/protected/reports/list.vue')
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	scrollBehavior(to) {
		if (to.hash) {
			return {
				path: to.path,
				el: to.hash
			};
		} else {
			return {
				x: 0,
				y: 0
			};
		}
	}
});

export default router;
