import http from './http-common';
import { TSourceFundingType } from '@/types/source';

export default class Funding {

	async list(): Promise <TSourceFundingType[]> {
		const response = await http.get('/funding/list');
		return response.data;
	}

}
