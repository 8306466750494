import http from './http-common';
import { TReportList } from '@/types/report';

export default class Reports {

	async list(): Promise<TReportList[]> {
		const response = await http.get('/reports/list');
		return response.data;
	}

	async save(data: FormData): Promise <boolean> {
		const response = await http.post('/reports/save', data);
		return response.data;
	}

}
