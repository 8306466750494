
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'Home',
	data() {
		return {
			sent: false,
			disabled: false,
			isComponentModalActive: true,
			form: {
				name: '',
				email: '',
				subject: '',
				message: ''
			},
			error: {
				name: false,
				email: false,
				subject: false,
				message: false
			}
		};
	},
	validate() {
		function isEmpty(value: string) {
			return value == null || value.trim() === '';
		}
		function isEmail(email: string) {
			const re = /\S+@\S+\.\S+/;
			return re.test(email);
		}

		this.error.name = isEmpty(this.form.name);
		this.error.email = isEmpty(this.form.email) || !isEmail(this.form.email);
		this.error.subject = isEmpty(this.form.subject);
		this.error.message = isEmpty(this.form.message);

		return !(this.error.name || this.error.email || this.error.subject || this.error.message);
	},
	send() {
		/*if (this.validate()) {
			this.disabled = true;
			// TOTO: disable form
			// this.$api.contacts.save(this.form).then(() => {
			//    this.sent = true;
			//    this.$refs.form.reset();
			//    this.disabled = false;
			// });
			this.$refs.form.reset();
			this.disabled = false;
			this.sent = true;
		}*/
	}
});
