import http from './http-common';

export default class Payments {

	async prepare(rptId: number) {
		const response = await http.post('/payments/prepare', { rptId: rptId });
		return response.data;
	}

}
