import http, { CommonApi } from "./http-common";
import { TUser } from '@/types/user';
import { Resp } from "@/types/express";

export default class Users extends CommonApi {

	/**
	 * Activation for profile.
	 */
	activate<T, E>(key: string): Promise<Resp<T, E>> {
		return this.requestPost<T, E>('/users/activate', { key });
	}


	/**
	 * Authentication by e-mail and pass.
	 */
	login<T, E>(email: string, pass: string): Promise<Resp<T, E>> {
		return this.requestPost<T, E>('/users/login', {
			email, pass
		});
	}


	/**
	 * Get profile details.
	 */
	get<T, E>(): Promise<Resp<T, E>> {
		return this.requestGet<T, E>('/users/get');
	}


	/**
	 * Update password for profile.
	 */
	password<T, E>(old: string, pass: string): Promise<Resp<T, E>> {
		return this.requestPost<T, E>('/users/password', {
			old,
			pass
		});
	}


	async register(data: TUser): Promise <string> {
		const response = await http.post('/users/register', data);
		return response.data;
	}


	/**
	 * Reset password by e-mail.
	 */
	async passwordResetPrepare(email: string): Promise <string> {
		const response = await http.post('/users/password/prepare', { email });
		return response.data;
	}


	/**
	 * Reset password by e-mail.
	 */
	async passwordReset(key: string, password: string): Promise <string> {
		const response = await http.post('/users/password/reset', {
			key,
			password
		});
		return response.data;
	}

	update<T, E>(data: TUser): Promise<Resp<T, E>>{
		return this.requestPost<T, E>('/users/update', data);
	}

}
