
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Menu',
	data() {
		return {
			logged: false,
			navbarMenu: false,
			isComponentModalActive: false
		};
	},
	mounted() {
		this.checkLogged();
	},
	methods: {
		showMenu() {
			this.navbarMenu = !this.navbarMenu;
		},
		checkLogged() {
			this.logged = !!localStorage.getItem('token');
		},
		logout() {
			localStorage.clear();
			this.$bus.emit('logged');
			this.$router.push({ name: 'Main' });
		}

	},
	computed: {
		showHero() {
			return this.$route.path === '/';
		}
	},
	created() {
		this.$bus.on('logged', this.checkLogged);
		this.$bus.on('logout', this.checkLogged);
	}
});
