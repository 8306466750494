/* eslint-disable  @typescript-eslint/no-explicit-any */

import axios, { AxiosResponse } from "axios";
import { Resp } from "@/types/express";

const http = axios.create({
	baseURL: '/api',
	headers: { 'Content-type': 'application/json' }
});


http.interceptors.request.use((config) => {
	const token = localStorage.getItem('token');
	if (token) {
		if (config.headers) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		// config.headers["x-access-token"] = token; // for Node.js Express back-end
	}
	return config;
}, (error) => {
	return Promise.reject(error);
});


export class CommonApi {

	protected requestPost<T, E>(url: string, data?: any): Promise<Resp<T, E>> {
		return new Promise((resolve, reject) => {
			http.post(url, data)
				.then((response: AxiosResponse<Resp<T, E>>) => {
					if (response.data.error) {
						reject(response.data);
					} else {
						resolve(response.data);
					}
				}).catch((e) => {
					reject(e);
				});
		});
	}

	requestGet<T, E>(url: string, data?: any): Promise<Resp<T, E>> {
		return new Promise((resolve, reject) => {
			http.get(url, data)
				.then((response: AxiosResponse<Resp<T, E>>) => {
					if (response.data.error) {
						reject(response.data);
					} else {
						resolve(response.data);
					}
				}).catch((e) => {
					reject(e);
				});
		});
	}

}

export default http;
