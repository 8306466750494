import { createApp } from 'vue';
import mitt from 'mitt';
import router from './router';
import App from './app.vue';
import ApiClient from './api';

import './assets/sass/main.sass';

// import VueSmoothScroll from 'vue3-smooth-scroll';

// import Vue from 'vue';

// import Buefy from 'buefy';

/*declare module '@vue/runtime-core' {
	export interface ComponentCustomProperties {
		$http: typeof axios
		$validate: (data: object, rule: object) => boolean
	}
}*/
// App.config.globalProperties.$http = axios;

//

//
// Vue.config.productionTip = false;
//

// // Vue.use(Buefy);
// Vue.prototype.$api = new ApiClient();
// Vue.prototype.$bus = new Vue();

/*
declare module 'vue/types/vue' {
	interface Vue {
		$api: ApiClient;
	}
}
*/

/*
Vue.prototype.$isLogged = () => {
	const exp: string | null = localStorage.getItem('expiration');
	if (exp) {
		const expiration: number = parseInt(exp, 10);
		const token = localStorage.getItem('token');
		if (expiration && token) {
			if (new Date().getTime() < expiration) {
				return true;
			}
		}
		localStorage.clear();
		Axios.defaults.headers.common.Authorization = null;
	}
	return false;
};
*/

/* check for jwt token */
// if (Vue.prototype.$isLogged()) {
//   const token = localStorage.getItem('token');
//   Axios.defaults.headers.common.Authorization2 = `Bearer ${token}`;
// }

const app = createApp(App);
app.config.globalProperties.$bus = mitt();
app.config.globalProperties.$api = new ApiClient();
app.use(router);
app.mount('#app');
