
import { defineComponent } from 'vue';

import Footer from '@/views/components/footer.vue';
import Menu from '@/views/components/menu.vue';

export default defineComponent({
	name: 'App',
	components: {
		Footer,
		Menu
	},
	data() {
		return {
			logged: false,
			firstName: null,
			lastName: null,
			log: null
		};
	},
	mounted() {
		// this.$bus.$on('logout', this.logout)
		// this.$bus.$on('logged', this.checkLogged);
		// this.checkLogged();
	},
	methods: {
		checkLogged() {
			// this.logged = localStorage.getItem('token');
			// this.firstName = localStorage.getItem('fName');
			// this.lastName = localStorage.getItem('lName');
		}
		// logout() {
		// 	localStorage.clear();
		// 	this.$emit('logged');
		// 	this.$router.push({
		// 		name: 'Main'
		// 	});
		// }
	}
});
