import http from './http-common';
import { TContact } from '@/types/contact';

export default class Contacts {

	async save(data: TContact): Promise <boolean> {
		const response = await http.post('/contacts/save', data);
		return response.data;
	}

}
