import http from './http-common';
import { TSector } from '@/types/sector';

export default class Sector {

	async list(): Promise <TSector> {
		const response = await http.get('sector/list');
		return response.data;
	}

}
