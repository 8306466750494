import Admin from './admin';
import Contacts from './contacts';
import Funding from './funding';
import Payments from './payments';
import Reports from './reports';
import Sector from './sector';
import Users from './users';

export default class ApiClient {

	public admin: Admin;
	public contacts: Contacts;
	public funding: Funding;
	public payments: Payments;
	public reports: Reports;
	public sector: Sector;
	public users: Users;

	constructor() {
		this.admin = new Admin();
		this.contacts = new Contacts();
		this.funding = new Funding();
		this.payments = new Payments();
		this.reports = new Reports();
		this.sector = new Sector();
		this.users = new Users();
	}

}
