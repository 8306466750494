import http from './http-common';

export default class Reports {

	async login(login: string, pass: string): Promise <boolean> {
		const response = await http.post('/adm/login', {
			login,
			pass
		});
		return response.data;
	}

	async logout(): Promise <boolean> {
		const response = await http.post('/adm/logout');
		return response.data;
	}

}
